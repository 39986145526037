body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-carousel .slick-dots li.slick-active button {
    background-color: #2d3f4f;
    width: 20px;
    height: 20px;
    border-radius: 10px;
}

.ant-carousel .slick-dots li button {
    background-color: #2d3f4f;
    width: 20px;
    height: 20px;
    border-radius: 10px;
}

